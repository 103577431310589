import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default () => {
  const [frgrndColor, setFrgrndColor] = useState("#FFFFFF");
  const [bkgrndColor, setBkgrndColor] = useState("#5F4190");
  return (
    <Layout>
      <SEO
        title="Color Contrast Analyzer"
        description="Use the Color Contrast Analyzer (CCA) to evaluate text readability and visual contrast between elements for better accessibility. Ensure compliance with WCAG standards."
      />
      <section id="colour-contrast-analyzer" className="colour-contrast-analyzer">
        <div className="container">
          <div className="section-title">
            <h1>Color Contrast Analyzer</h1>
          </div>
          <div className="content-section">
            <p>The Color Contrast Analyzer (CCA) assists in evaluating the readability of text and the difference in contrast between visual elements, including graphical components and visual cues.</p>
            <div id="halfaccessible-contrast-analyzer">
                <div>
                    <div className="card text-left mb-2">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="form-group d-flex flex-row mb-3">
                                        <div className="flex-grow-1">
                                            <label className="form-label" htmlFor="frgrnd">Foreground</label>
                                            <div className="input-group">
                                                <input className="form-control" type="text" value={frgrndColor} id="frgrnd" onChange={(e) => setFrgrndColor(e.target.value)} />                                                
                                                <div className="input-group-append d-flex align-items-center frgrndColorPicker">
                                                    <input type="color" name="frgrndColorPicker" id="frgrndColorPicker" className="btn px-1 py-0" value={frgrndColor} title="Foreground color picker" onChange={(e) => setFrgrndColor(e.target.value)} /> 
                                                    <label htmlFor="frgrndColorPicker" className="sr-only text-dark">Foreground color picker</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-end justify-content-end">
                                            <button type="button" id="btnCopyFore" className="border border-0 bg-white text-blue"><span className="sr-only">Copy to clipboard foreground color</span><i className="icofont icofont-copy my-auto ms-1 fs-4" aria-hidden="true" title="Copy to clipboard"></i></button> 
                                        </div>
                                    </div>
                                    <div className="my-3">
                                        <div className="spectrum-area">
                                            <label htmlFor="frgrndSpectrum">Lightness</label>
                                            <input id="frgrndSpectrum" type="range" min="0" max="100" step="1" className="spectrum-range" />
                                            <div className="spectrum" style={{ background: 'linear-gradient(to right, rgb(0, 0, 0), rgb(0, 0, 255), rgb(255, 255, 255))' }}></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2 d-flex align-items-center justify-content-center">
                                    <button id="btnSwap" className="btn mt-3 mb-2">
                                        <svg role="img" aria-labelledby="swapfbc" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left-right" viewBox="0 0 16 16" >
                                            <title id="swapfbc">Swap values</title>
                                            <path fill-rule="evenodd" d="M1 11.5a.5.5 0 0 0 .5.5h11.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 11H1.5a.5.5 0 0 0-.5.5zm14-7a.5.5 0 0 1-.5.5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H14.5a.5.5 0 0 1 .5.5z"/>
                                        </svg>
                                        <span className="sr-only">&nbsp;of Foreground and Background color</span>
                                    </button>
                                </div>
                                <div className="col-md-5">
                                    <div className="form-group d-flex flex-row mb-3">
                                        <div className="flex-grow-1">
                                            <label className="form-label" htmlFor="bkgrnd">Background</label>
                                            <div className="input-group">
                                                <input className="form-control" type="text" value={bkgrndColor} id="bkgrnd" onChange={(e) => setBkgrndColor(e.target.value)} />                                                
                                                <div className="input-group-append d-flex align-items-center bkgrndColorPicker">
                                                    <input type="color" name="bkgrndColorPicker" id="bkgrndColorPicker" className="btn px-1 py-0" value={bkgrndColor} title="Background color picker" onChange={(e) => setBkgrndColor(e.target.value)} />
                                                    <label htmlFor="bkgrndColorPicker" className="sr-only text-dark">Background color picker</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-end justify-content-end">
                                            <button type="button" id="btnCopyBack" className="border border-0 bg-white text-blue"><span className="sr-only">Copy to clipboard background color</span><i className="icofont icofont-copy my-auto ms-1 fs-4" aria-hidden="true" title="Copy to clipboard"></i></button> 
                                        </div>
                                    </div>
                                    <div className="my-3">
                                        <div className="spectrum-area">
                                            <label htmlFor="bkgrndSpectrum">Lightness</label>
                                            <input id="bkgrndSpectrum" type="range" min="0" max="100" step="1" className="spectrum-range" />
                                            <div className="spectrum" style={{ background: 'linear-gradient(to right, rgb(0, 0, 0), rgb(0, 0, 255), rgb(255, 255, 255))' }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="card text-left mb-2">
                                <div className="card-body text-center align-items-end justify-content-center">
                                    <div className="d-flex align-items-end justify-content-center py-4 h2 flex-column flex-md-row" role="status" aria-relevant="all">
                                        <span className="font-weight-bold m-0">Contrast Ratio: &nbsp;</span>
                                        <span className="sr-only" id="sr-fg-bg-color"></span>
                                        <span id="fg-bg-color" className="font-weight-bold m-0" aria-live="off" aria-hidden="true"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="card text-left mb-2" id="output_preview">
                                <div className="card-body text-center py-5" aria-hidden="true">
                                    <p className="small-smpl-txt mb-3">Small sample text: 14pt (<span><i>18.66px</i></span>)</p>
                                    <p className="large-smpl-txt mb-0">Large sample text: 18pt (<span><i>24px</i></span>)</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="card text-left mb-2">
                                <div className="card-body">
                                    <h2 className="h5 mb-4 mt-2 text-center font-weight-bold">WCAG Results</h2>
                                    <ul className="list-group m-0">
                                        <li className="list-group-item p-0">
                                            <p className="m-0 px-3 py-2 text-white bg-blue">1.4.3 Contrast (Minimum) (AA)</p>
                                            <p className="m-0">
                                                <span className="m-1 badge p-0 fw-normal text-blue text-left w-50 lh-lg my-2" id="ml-v"></span>
                                                <span className="m-1 badge p-0 fw-normal text-blue text-left w-auto my-2" id="mr-v"></span>
                                            </p>
                                        </li>
                                        <li className="list-group-item p-0">
                                            <p className="m-0 px-3 py-2 text-white bg-blue">1.4.6 Contrast (Enhanced) (AAA)</p>
                                            <p className="m-0">
                                                <span className="m-1 badge p-0 fw-normal text-blue text-left w-50 lh-lg my-2" id="mxl-v"></span>
                                                <span className="m-1 badge p-0 fw-normal text-blue text-left w-auto my-2" id="mxr-v"></span>
                                            </p>
                                        </li>
                                        <li className="list-group-item p-0">
                                            <p className="m-0 px-3 py-2 text-white bg-blue">1.4.11 Non-text Contrast (AA)</p>
                                            <p className=" mb-0">
                                                <span className="m-1 badge p-0 fw-normal text-blue text-left text-wrap lh-lg my-2" id="mui-v"></span>
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card text-left mb-2">
                        <div className="card-body">
                            <h2 className="h5 mb-4 mt-2 text-center font-weight-bold">Get Color Recommendations</h2>
                            <form action="javascript:void(0);">
                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="form-floating p-0 my-1 mb-2 form-select-container">
                                            <select className="form-select" id="selectSC">
                                                <option selected value="143">1.4.3 Contrast (Minimum) (AA)</option>
                                                <option value="1411">1.4.11 Non-text Contrast</option>
                                                <option value="146">1.4.6 Contrast (Enhanced) (AAA)</option>
                                            </select>
                                            <label htmlFor="selectSC">Select a Success Criterion</label>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-floating p-0 my-1 mb-2 form-select-container">
                                            <select className="form-select" id="textsize">
                                                <option selected value="R">Regular</option>
                                                <option value="L">Large</option>
                                            </select>
                                            <label htmlFor="textsize">Select Text Type</label>
                                        </div>
                                    </div>
                                    <div className="col-md-2 d-flex align-items-end mb-2">
                                        <button className="btn  p-3" type="submit" id="suggested-color">Submit</button>
                                    </div>
                                </div>
                            </form>
                            <div id="collapseSuggestions" tabindex="-1">
                                <div className="row">
                                    <div id="first-set" className="col-md-6 text-center mt-4">
                                        <h3 className="h5 mb-4">Suggestion 1</h3>
                                        <div className="row justify-content-center mb-2" aria-hidden="true">
                                            <div className="color-div-container d-flex flex-column col-auto align-items-center">
                                                <div id="colorS-1" className="color-divs"></div>
                                                <p className="m-0 common-sub-heading-font" id="colorS-1-p"></p>
                                            </div>
                                            <div className="color-div-container col-auto d-flex flex-column align-items-center">
                                                <div id="colorS-2" className="color-divs"></div>
                                                <p className="m-0 common-sub-heading-font" id="colorS-2-p"></p>
                                            </div>
                                        </div>
                                        <p className="common-sub-heading-font m-0">Color Contrast ratio <strong id="colorS-ratio"></strong></p>
                                        <p className="sr-only" id="suggestion1"></p>
                                    </div>
                                    <div id="second-set" className="col-md-6 text-center mt-4">
                                        <h3 className="h5 mb-4">Suggestion 2</h3>
                                        <div className="row justify-content-center mb-2" aria-hidden="true">
                                            <div className="color-div-container d-flex flex-column col-auto align-items-center">
                                                <div id="colorSS-1" className="color-divs"></div>
                                                <p className="m-0 common-sub-heading-font" id="colorSS-1-p"></p>
                                            </div>
                                            <div className="color-div-container col-auto d-flex flex-column align-items-center">
                                                <div id="colorSS-2" className="color-divs"></div>
                                                <p className="m-0 common-sub-heading-font" id="colorSS-2-p"></p>
                                            </div>
                                        </div>
                                        <p className="common-sub-heading-font m-0">Color Contrast ratio <strong id="colorSS-ratio"></strong></p>
                                        <p className="sr-only" id="suggestion2"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card text-left mb-2">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 className="h5 mb-4 mt-2 font-weight-bold">Explanation</h2>
                                    <p>Please input both foreground and background colors in the RGB hexadecimal format (e.g., #FFFFFF or #5F4190), or alternatively, select a color using the provided color picker tool.</p>
                                    <p>Compliance with WCAG 2.0 level AA standards necessitates achieving a contrast ratio of no less than 4.5:1 for regular text and 3:1 for text of a larger size. WCAG 2.1 guidelines extend the requirement to graphics and user interface elements, such as form input borders, necessitating a minimum contrast ratio of 3:1. For adherence to the more stringent WCAG Level AAA standards, a contrast ratio of 7:1 is mandated for normal text, and 4.5:1 for larger text.</p>
                                    <p className="m-0">Larger text, as per the WCAG standards, is specifically defined as text that is 14 points (typically 18.66px) or larger and bold, or text that is 18 points (typically 24px) or larger.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card text-left mb-2">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2 className="h5 mb-4 mt-2 font-weight-bold">Reference to</h2>
                                    <ul className="m-0">
                                        <li><a href="https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html" target="_blank" rel="noopener noreferrer"><em>https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html</em></a></li>
                                        <li><a href="https://www.w3.org/WAI/WCAG21/Understanding/contrast-enhanced" target="_blank" rel="noopener noreferrer"><em>https://www.w3.org/WAI/WCAG21/Understanding/contrast-enhanced</em></a></li>
                                        <li><a href="https://www.w3.org/WAI/WCAG21/Understanding/non-text-contrast.html" target="_blank" rel="noopener noreferrer"><em>https://www.w3.org/WAI/WCAG21/Understanding/non-text-contrast.html</em></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: 11 }}>
                <div id="LiveNotify" className="toast" role="alert" aria-live="assertive" aria-atomic="true"></div>
            </div>
            <div id="canvas"></div>
          </div>
        </div>
      </section>
    </Layout>
  );
}